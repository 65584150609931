import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { StateService, PaginatorComponent } from "@puntaje/shared/core"
import { GeneradorInstrumento, GeneradorInstrumentos, Plataformas, TipoInstrumento, TipoInstrumentos } from "@puntaje/nebulosa/api-services"
import {
    GeneradorInstrumentoDiagnosticoEvaluaciones,
    GeneradorInstrumentoDiagnosticoView
} from "@puntaje/puntaje/api-services"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
import { Plataforma } from "@puntaje/achievements/api-services"
declare const config: AppConfig

@Component({
    selector: "generador-diagnostico",
    templateUrl: "generador-diagnostico.component.html"
})
export class GeneradorDiagnosticoComponent implements OnInit {
    generadores: GeneradorInstrumento[]
    tipoInstrumentosDiagnostico: TipoInstrumento[]
    evaluacionTipoDiagnostico: string[]
    config = config
    plataforma: Plataforma
    params = GeneradorInstrumentoDiagnosticoView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    sub: Subscription
    searchParams: any

    constructor(
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected generadorInstrumentoDiagnosticoEvaluacionesService: GeneradorInstrumentoDiagnosticoEvaluaciones,
        protected tipoInstrumentosService: TipoInstrumentos,
        protected plataformasService: Plataformas,
        protected router: Router,
        protected simpleModalService: SimpleModalService
    ) {}

    async ngOnInit() {
        this.evaluacionTipoDiagnostico = config.plataforma.evaluacionTipos.filter(et => et.includes("diagnostico"))
        this.tableButtonsArray = {
            asociar_evaluacion_diagnostico: {
                function: this.add.bind(this),
                text: "Asociar Evaluación",
                glyphicon: "glyphicon-link",
                showIf: generador => !generador.evaluacion_id
            },
            editar: {
                function: this.edit.bind(this),
                text: "Editar Asociación",
                glyphicon: "glyphicon-pencil",
                showIf: generador => generador.evaluacion_id
            },
            eliminar: {
                function: this.showModalDelete.bind(this),
                text: "Eliminar Asociación",
                glyphicon: "glyphicon-trash",
                showIf: generador => generador.evaluacion_id
            }
        }

        await this.plataformasService
            .where({
                plataforma: {
                    plataforma: config.plataforma.name
                }
            })
            .then(plataformas => (this.plataforma = plataformas[0]))
    }

    showAll(page: number, per: number) {
        if (this.loadingLayout) this.loadingLayout.standby()
        const params = {
            ...this.searchParams,
            tipo_instrumento: {
                tipo_instrumento: this.evaluacionTipoDiagnostico
            },
            asignatura_plataforma: {
                plataforma_id: this.plataforma?.id
            },
            page: page,
            per: per,
            include: "[tipo_instrumento,asignatura_plataforma:[asignatura,plataforma]]"
        }

        this.generadorInstrumentosService.disableIgnoreModel()

        return this.generadorInstrumentosService
            .where(params)
            .then((generadores: GeneradorInstrumento[], total: number) => {
                if (generadores.length > 0) {
                    this.generadorInstrumentosService.enableIgnoreModel()
                    this.generadorInstrumentoDiagnosticoEvaluacionesService
                        .where({
                            generador_instrumento_id: {
                                in: generadores.map(g => g.id)
                            }
                        })
                        .then(generadorInstrumentoDiagnosticoEvaluaciones => {
                            generadores.forEach(g => {
                                const generadorInstrumentoDiagnosticoEvaluacion =
                                    generadorInstrumentoDiagnosticoEvaluaciones.find(
                                        gide => gide.generador_instrumento_id === g.id
                                    )
                                g["evaluacion_id"] = generadorInstrumentoDiagnosticoEvaluacion?.evaluacion_id
                                g["generador_instrumento_diagnostico_evaluacion_id"] =
                                    generadorInstrumentoDiagnosticoEvaluacion?.id
                                this.loadingLayout.ready()
                            })
                            this.generadores = generadores
                        })
                } else {
                    this.generadores = []
                    this.loadingLayout.ready()
                }

                return total
            })
    }

    add(generador) {
        this.router.navigate([`generador_diagnostico/${generador.id}/asociar_evaluacion`])
    }

    edit(generador) {
        this.router.navigate(["generador_diagnostico/" + generador.id + "/asociar_evaluacion" + "/" + generador.evaluacion_id])
    }

    delete(generador) {
        this.generadorInstrumentoDiagnosticoEvaluacionesService
            .remove(generador.generador_instrumento_diagnostico_evaluacion_id)
            .then(() => {
                this.paginator.reload()
            })
    }

    showModalDelete(generador) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            `¿Está seguro que desea eliminar la Evaluación ${generador.evaluacion_id} asociada al Generador ${generador.id} ?`
        )
        this.simpleModalService.setModalCallback(() => this.delete(generador))
        this.simpleModalService.showSimpleModal()
    }
}
