<loading-layout #loadingLayout>
    <div class="form-group">
        <label>Evaluacion de Diagnostico</label>
        <input type="number" class="form-control" [placeholder]="'Evaluacion ID'" [(ngModel)]="evaluacionId" #ctrl="ngModel"/>
    </div>
    <div *ngIf="msg">
        <alert *ngIf="!valid" type="danger">
            <p>{{msg}}</p>
        </alert>
        <alert *ngIf="valid" type="success">
            <p>{{msg}}</p>
        </alert>
    </div>
    <button class="btn btn-default align-right" (click)="save()">Guardar</button>
    <button class="btn btn-default" (click)="back()">Volver</button>
</loading-layout>