
import { Component } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

@Component({
    templateUrl: "generador-diagnostico-evaluacion.edit.component.html"
})
export class GeneradorDiagnosticoEvaluacionEditComponent {
    sub: Subscription
    generadorInstrumentoId: number
    evaluacionId: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.instrumentos.instrumentos_predefinidos.edit")
        this.sub = this.route.params.subscribe(params => {
            this.generadorInstrumentoId = +params["id"]
            this.evaluacionId = +params["evaluacionId"]
        })
    }
}