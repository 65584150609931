import { DateView, LinkView, TextView, BooleanView } from "@puntaje/shared/core"
import { GeneradorInstrumentos,GeneradorInstrumento } from "@puntaje/nebulosa/api-services"

export class GeneradorInstrumentoDiagnosticoView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: TextView, tableVisible: true },
            generador_instrumento: {
                label: "Nombre",
                type: TextView,
                tableVisible: true,
                options: { class: GeneradorInstrumentos, key: "id" }
            },
            asignatura: {
                label: "Asignatura",
                type: TextView,
                tableVisible: true,
                options: {
                    showFn: (generador: GeneradorInstrumento) =>
                        generador &&
                        generador.asignatura_plataforma &&
                        generador.asignatura_plataforma.asignatura &&
                        generador.asignatura_plataforma.asignatura.asignatura
                }
            },
            tipo_instrumento: {
                label: "Tipo Instrumento",
                type: TextView,
                tableVisible: true,
                options: {
                    showFn: (generador: GeneradorInstrumento) => generador?.tipo_instrumento?.tipo_instrumento
                }
            },
            evaluacion_id: {
                label: "ID Evaluación",
                type: TextView,
                tableVisible: true,
            },
            valido: { label: "Válido", type: BooleanView, tableVisible: true },
            numero_preguntas: { label: "Número preguntas", type: TextView, tableVisible: true },
            maximo_alternativas: { label: "Máximo Nº Alternativas", type: TextView, tableVisible: true },
            tiempo: { label: "Tiempo", type: TextView, tableVisible: true },
            orden: { label: "Orden", type: TextView, tableVisible: true },
            alias: { label: "Alias", type: TextView, tableVisible: true },

            grupal: { label: "Grupal", type: BooleanView, tableVisible: false },
            multiple: { label: "Multiple", type: BooleanView, tableVisible: false },
            visible: { label: "Visible", type: BooleanView, tableVisible: true },
            numero_preguntas_piloto: { label: "N° Preguntas piloto", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
